/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import React from "react";
import { graphql } from "gatsby";
import LayoutBox from "../components/layout/layout-box";
import Layout from "../components/layout/layout";
import RichText from "../components/richtext/rich-text";

export type PageTemplateProps = {
  data: Queries.PageBySlugQuery;
  pageContext: any;
};
export const PageTemplate: React.FC<PageTemplateProps> = ({
  data,
  pageContext,
}) => {
  //console.log("page data", data, pageContext);
  return (
    <Layout
      header={data.contentfulPage?.header as Queries.PageHeaderFragment}
      hero={data.contentfulPage?.hero as Queries.PageHeroFragment}
    >
      <Box py={4}>
        <LayoutBox>
          <RichText
            {...data.contentfulPage?.content}
            sx={{
              fontSize: [2, 3],
              lineHeight: ["1.1em"],
              textAlign: ["center", "left"],
            }}
          ></RichText>
        </LayoutBox>
      </Box>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      hero {
        ...PageHero
      }
      header {
        ...PageHeader
      }
      content {
        ...PageContent
      }
    }
  }
`;
